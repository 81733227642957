var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "table-content pt-2" },
      [
        _vm.usuarios && _vm.usuarios.length > 0
          ? [
              _c(
                "vs-table",
                {
                  staticClass: "table-list",
                  attrs: {
                    pagination:
                      _vm.usuarios && _vm.usuarios.length > _vm.itemsPerPage,
                    "max-items": _vm.itemsPerPage,
                    data: _vm.usuarios || [],
                    hoverFlat: true,
                    search: "",
                    noDataText: "Nenhum usuário encontrado"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var data = ref.data
                          return _vm._l(data, function(usuario, indextr) {
                            return _c(
                              "vs-tr",
                              {
                                key: indextr,
                                class: {
                                  "bg-danger-gradient": !usuario.IsActive
                                },
                                attrs: { data: usuario }
                              },
                              [
                                _vm.actions && _vm.actions.length
                                  ? _c(
                                      "vs-td",
                                      [
                                        _c(
                                          "vs-dropdown",
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              "vs-custom-content": "",
                                              "vs-trigger-click": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                attrs: {
                                                  color: "dark",
                                                  type: "flat",
                                                  title: "Ações"
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "onpoint-dots-three-vertical icon-dots-three-vertical",
                                                  attrs: { role: "button" }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "vs-dropdown-menu",
                                              {
                                                staticStyle: { width: "200px" }
                                              },
                                              _vm._l(_vm.actions, function(
                                                userAction,
                                                indexAction
                                              ) {
                                                return _c(
                                                  "vs-dropdown-item",
                                                  {
                                                    key: indexAction,
                                                    attrs: {
                                                      disabled: userAction.allowedRole
                                                        ? _vm.disabledOption(
                                                            usuario
                                                          )
                                                        : false
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return userAction.action(
                                                          usuario
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-base px-6 py-2"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              userAction.name
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("vs-td", { staticClass: "text-center" }, [
                                  _vm._v(_vm._s(usuario.Name))
                                ]),
                                _c("vs-td", { staticClass: "text-center" }, [
                                  _vm._v(_vm._s(usuario.Email))
                                ]),
                                _c("vs-td", { staticClass: "text-center" }, [
                                  _vm._v(_vm._s(usuario.PhoneNumber))
                                ]),
                                _c("vs-td", { staticClass: "text-center" }, [
                                  _c("span", { staticClass: "font-semibold" }, [
                                    _vm._v(
                                      _vm._s(
                                        usuario.IsActive ? "Ativo" : "Inativo"
                                      )
                                    )
                                  ])
                                ]),
                                _c("vs-td", { staticClass: "text-center" }, [
                                  _vm._v(_vm._s(usuario.ProfileName))
                                ])
                              ],
                              1
                            )
                          })
                        }
                      }
                    ],
                    null,
                    false,
                    2066648262
                  )
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _vm.actions && _vm.actions.length
                        ? _c("vs-th", { staticClass: "text-center" }, [
                            _vm._v("\n            Ações\n          ")
                          ])
                        : _vm._e(),
                      _c("vs-th", [_vm._v("Nome")]),
                      _c("vs-th", [_vm._v("E-mail")]),
                      _c("vs-th", [_vm._v("Telefone")]),
                      _c("vs-th", [_vm._v("Ativo")]),
                      _c("vs-th", [_vm._v("Perfil")])
                    ],
                    1
                  )
                ],
                2
              )
            ]
          : [_vm._m(0)]
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto text-center mt-32"
      },
      [
        _c("p", [
          _c("span", { staticClass: "material-icons text-6xl" }, [
            _vm._v("description")
          ])
        ]),
        _c("p", [_vm._v("Você ainda não possui nenhum tomador cadastrado.")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }